@import '../../partials/variable';
body {
    display: flex;
    justify-content: center;
    align-items: center;
    background: $secondary1;
    #confetti {
        height: 100%;
        left: 0px;
        position: fixed;
        top: 0px;
        width: 100%;
        z-index: -1;
    }
    .wrapper {
        .maintenance {
            padding: 5%;
        }
        color: $primary0;
        display: flex;
        justify-content: center;
        flex-direction: row;
        align-items: center;
        #game {
            position: relative;
            .text {
                position: absolute;
                top: 80%;
                left: 42%;
                p {
                    text-align: center;
                    opacity: 0.2;
                }
            }
            canvas {
                width: 500px;
                padding: 5% 10% 5%;
            }
        }
        #explanation {
            border-left: 1px solid $primary0;
            display: flex;
            justify-content: space-around;
            flex-direction: column;
            padding-right: 10%;
            h1 {
                font-size: 8rem;
                padding-left: 10%;
            }
            p {
                font-size: 1.5rem;
                padding-left: 10%;
                width: 75%;
            }
            a {
                padding: 5% 0 10% 10%;
                color: white;
                text-decoration: underline;
                font-size: 2rem;
                transition: all 0.3s ease;
                &:hover {
                    text-decoration-color: $primary0;
                }
            }
        }
    }
}
