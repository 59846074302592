/////////////////* COLOR */////////////////
/*primary color*/

$primary0: #14b9d5; // Blue for the login page (same as the logo)
// Accent color for the user
$purple: rebeccapurple;
$orange: orange;
$crimson: crimson;
$tomato: tomato;
$green: #2ecc71;
$blue: #2980b9;

/*secondary color*/

$secondary1: #1e1e24; // DarkGray for the <aside> 
$secondary2: #999; // No actual use
/*side color*/

$success: #4F8A10;
$warning: #FF7416;
$error: #D8000C;
$info: #00529B;

/*gradient color*/

$gradcolor1:#34353e;
$gradcolor2:#24252a;
$gradient1: linear-gradient($gradcolor1, $gradcolor2); // gradient for the right rightside of the dashboard and lightbox
/////////////////* HEADING */////////////////
$h1: 1.875rem;
$h2: 1.5rem;
$h3: 1.375rem;
$h4: 1.25rem;
$h5: 1.125rem;
$h6: 1rem;
/////////////////* MEDIA QUERIE */////////////////
/*xlarge*/

$xlarge_max_width:1920px;
$xlarge_min_width:1367px;

/*large*/

$large_max_width:1366px;
$large_min_width:1025px;

/*medium*/

$medium_max_width:1024px;
$medium_min_width:769px;

/*small*/

$small_max_width:768px;
$small_min_width:321px;

/*xsmall*/

$xsmall_max_width:320px;
$xsmall_min_width:0px;

/* tablet landscape */

$tablet_landscape_max_width:1025px;
$tablet_landscape_min_width:480px;

/* tablet portrait */

$tablet_portrait_max_width:768px;
$tablet_portrait_min_width:320px;

/* phone landscape */

$phone_landscape_max_width:736px;
$phone_landscape_min_width:480px;

/* phone portrait */

$phone_portrait_max_width:414px;
$phone_portrait_min_width:320px;
